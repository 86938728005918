import React from 'react'
import PropTypes from 'prop-types'
import { EditableInput } from '../../../../../../../common/EditableInput'
import {
  Input,
  CheckBox,
  Button,
  Select,
  ModalContent,
  ModalFooter,
  TextArea,
  ErrorControl,
} from '../../../../../../../common'

export const INPUT_TYPES = {
  INT: 'number',
  STRING: 'text',
  SELECT: 'text',
  JSON: 'text',
}

export const PropertiesFormComponent = ({
  header,
  property,
  formErrors,
  currentAvailableValue,
  actions: {
    handleSubmit,
    handleClose,
    updateInputState,
    setCurrentAvailableValue,
    addAvailableValue,
    editAvailableValue,
    deleteAvailableValue,
  },
}) => {
  const propOptions = Object.keys(INPUT_TYPES).map((type) => ({
    name: type,
  }))
  return (
    <ModalContent headline={header}>
      <div className="service-property-form__field wb-margin-bottom-xs">
        <Input
          label="Property name"
          value={property.name}
          error={formErrors.name}
          onChange={(e) => updateInputState('name', e.target.value)}
        />
      </div>
      <div className="service-property-form__field wb-margin-bottom-xs">
        <Select
          onChange={(e) => updateInputState('type', e.target.value)}
          error={formErrors.type}
          value={property.type}
          label="Type selection"
          placeHolder="Please choose a property type"
          options={propOptions}
        />
      </div>
      <div className="service-property-form__field wb-margin-bottom-xs">
        <CheckBox
          disabled={!property.type}
          checked={property.required}
          label="Required property"
          onChange={() => updateInputState('required', !property.required)}
        />
      </div>
      {property.type === 'SELECT' && (
        <div className="service-property-form__options-generator">
          <h3 className="wb-type-heading-xs wb-margin-bottom-xs">
            Please enter select options
          </h3>
          <div className="service-property-form__field">
            <div className="service-property-form__select-generator">
              <Input
                label="Select option"
                value={currentAvailableValue}
                error={formErrors.currentAvailableValue}
                onChange={(e) => setCurrentAvailableValue(e.target.value)}
              />
              <Button
                onClick={addAvailableValue}
                variant="primary"
                size="small"
                className="wb-margin-start-s"
              >
                Add new
              </Button>
            </div>
            <div className="wb-margin-bottom-m">
              {property.availableValues.map((val, index) => (
                <div
                  key={val}
                  className="service-property-form__input-wrapper wb-margin-top-3xs"
                >
                  <EditableInput
                    value={val}
                    onSave={(changedInput) =>
                      editAvailableValue(changedInput, index)
                    }
                    onDelete={() => deleteAvailableValue(index)}
                  />
                </div>
              ))}
            </div>
            <div>
              <ErrorControl error={formErrors.availableValues} />
            </div>
          </div>
        </div>
      )}
      <div className="service-property-form__field wb-margin-bottom-xs">
        {property.type &&
          property.required &&
          (property.type === 'JSON' ? (
            <TextArea
              label="Service"
              value={property.defaultValue}
              error={formErrors.json}
              onChange={(e) => updateInputState('defaultValue', e.target.value)}
            />
          ) : (
            <Input
              label="Default Value"
              type={INPUT_TYPES[property.type]}
              value={property.defaultValue}
              error={formErrors.defaultValue}
              onChange={(e) => updateInputState('defaultValue', e.target.value)}
            />
          ))}
      </div>
      <ModalFooter>
        <Button variant="secondary" size="medium" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          size="medium"
          className="wb-margin-start-s"
        >
          Submit
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

PropertiesFormComponent.propTypes = {
  header: PropTypes.string,
  currentAvailableValue: PropTypes.string,
  formErrors: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    currentAvailableValue: PropTypes.string,
    json: PropTypes.string,
    availableValues: PropTypes.string,
    defaultValue: PropTypes.string,
  }),
  property: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    platformServiceId: PropTypes.number,
    availableValues: PropTypes.array,
  }),
  actions: PropTypes.shape({
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
    updateInputState: PropTypes.func,
    setCurrentAvailableValue: PropTypes.func,
    addAvailableValue: PropTypes.func,
    editAvailableValue: PropTypes.func,
    deleteAvailableValue: PropTypes.func,
  }),
}
