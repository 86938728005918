import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import validate from '../../../../../../util/helpers/validate'
import { Input } from '../../../../../common'

export const GithubEndpointGenerator = ({ state, setState }) => {
  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        touched: true,
        valid: validate(value, state[control].validationRules, state),
      },
    })
  }

  return (
    <div className="wh-github-form">
      <div className="wb-margin-bottom-xs wh-github-form__url">
        <div>https://git.i.mercedes-benz.com/</div>
        <div>
          <input
            type="text"
            value={state.organization.value}
            placeholder="Organization"
            className={classnames({
              error: state.organization.touched && !state.organization.valid,
            })}
            onChange={(e) => updateInputState('organization', e.target.value)}
          />
        </div>
        <div>/</div>
        <div>
          <input
            type="text"
            placeholder="Repository"
            className={classnames({
              error: state.repository.touched && !state.repository.valid,
            })}
            value={state.repository.value}
            onChange={(e) => updateInputState('repository', e.target.value)}
          />
        </div>
        <div>/.github/workflows/</div>
        <div>
          <input
            type="text"
            value={state.workflowFileName.value}
            className={classnames({
              error:
                state.workflowFileName.touched && !state.workflowFileName.valid,
            })}
            placeholder="Workflow file"
            onChange={(e) =>
              updateInputState('workflowFileName', e.target.value)
            }
          />
        </div>
        <div>@</div>
        <div>
          <input
            type="text"
            value={state.gitReference.value}
            className={classnames({
              error: state.gitReference.touched && !state.gitReference.valid,
            })}
            placeholder="Git Reference"
            onChange={(e) => updateInputState('gitReference', e.target.value)}
          />
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <Input
          label="Access Token (optional)"
          value={state.githubToken.value}
          placeholder="Github Token"
          onChange={(e) => updateInputState('githubToken', e.target.value)}
        />
      </div>
    </div>
  )
}

const InputProps = PropTypes.shape({
  value: PropTypes.string,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  validationRules: PropTypes.shape({
    isValidUrl: PropTypes.bool,
  }),
})

GithubEndpointGenerator.propTypes = {
  state: PropTypes.shape({
    organization: InputProps,
    repository: InputProps,
    workflowFileName: InputProps,
    gitReference: InputProps,
    githubToken: InputProps,
  }),
  setState: PropTypes.func,
}
