import React, { useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  setModal,
  removeTenantMember,
  batchRemoveTenantMembers,
  extendTenantMembers,
  updateTenantMemberRoles,
  postNotification,
  bacthUpdateTenantMemberLabels,
} from '../../../../../../../data/actions'
import {
  generateColumns,
  generateSortedAndFilteredTenantMembers,
} from '../utils'
import { fetchExpiringTenantMembers } from '../../../../../../../http'
import { TableHeader } from '../components/TableHeader'
import { TanstackTableWrapper } from '../../../../../../common'

export const generateRemovedItems = (initialItemsList, newItemsList) =>
  initialItemsList
    .filter((label) => !newItemsList.includes(label))
    .map((label) => ({ label, value: label }))

export const generateAddedItems = (initialItemsList, newItemsList) =>
  newItemsList
    .filter((label) => !initialItemsList.includes(label))
    .map((label) => ({
      label,
      value: label,
    }))

export const generateRemovedRolesList = (initialRoles, newRoles, rolesObject) =>
  initialRoles
    .filter((role) => !newRoles.includes(role))
    .map((role) => ({
      label: rolesObject[role],
      value: role,
    }))

export const generateAddedRolesList = (initialRoles, newRoles, rolesObject) =>
  newRoles
    .filter((role) => !initialRoles.includes(role))
    .map((role) => ({
      label: rolesObject[role],
      value: role,
    }))

const TenantMembers = ({
  currentTenantMembers,
  tenantRoles,
  dispatchModal,
  extendMembers,
  notify,
  removeMember,
  updateMemberRoles,
  batchRemoveMembers,
  bacthUpdateMemberLabels,
}) => {
  const { acronym } = useParams()
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)
  const fetchExpiring = searchParams.get('expiring') || false

  const [selectedRoles, setSelectedRoles] = useState([])
  const [selectedLabels, setSelectedLabels] = useState([])
  const [selectedAction, setSelectedAction] = useState('')
  const [editableRowIndex, setEditableRowIndex] = useState(-1)
  const [rowSelection, setRowSelection] = useState({})
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [showExpiring, setShowExpiring] = useState(fetchExpiring)

  const [addLabels, setAddLabels] = useState([])

  const [globalFilter, setGlobalFilter] = useState('')
  const [data, setData] = useState([])
  const currentMembersObj = useRef(null)

  const [extendFormData, setExtendFormData] = useState({
    tenantUserRoleExpiryDate: '',
  })

  const rolesObject = {}
  const rolesList = tenantRoles.map(({ name, description }) => {
    rolesObject[name] = description
    return {
      label: description,
      value: name,
    }
  })

  const selectedUsers = Object.keys(rowSelection).map((index) => data[index])

  const changeMemberRoles = (rowIndex) => {
    const currentMember = data[rowIndex]
    const { daimlerId, roles, labels, tenantMemberUntil } = currentMember
    const oldRoles = currentMembersObj.current[daimlerId].roles || []
    const oldLabels = currentMembersObj.current[daimlerId].labels || []

    const deletedRoles = generateRemovedRolesList(oldRoles, roles, rolesObject)
    const addedRoles = generateAddedRolesList(oldRoles, roles, rolesObject)

    const deletedLabels = generateRemovedItems(oldLabels, labels)
    const addedLabels = generateAddedItems(oldLabels, labels)

    dispatchModal({
      variant: 'tenantMembersModal',
      action: 'changeRoles',
      onApprove: () =>
        updateMemberRoles(
          {
            tenantAcr: acronym,
            daimlerId,
            tenantUserRoleExpiryDate: tenantMemberUntil,
            roles,
            labels,
          },
          () => {
            dispatchModal({})
            setEditableRowIndex(-1)
          },
        ),
      data: {
        users: [currentMember],
        addedRoles,
        deletedRoles,
        addedLabels,
        deletedLabels,
        dateChanged:
          tenantMemberUntil !==
          currentMembersObj.current[daimlerId].tenantMemberUntil,
      },
      onCancel: () => dispatchModal({}),
    })
  }

  const updateInputState = (variant, value) =>
    setExtendFormData({
      ...extendFormData,
      [variant]: value,
    })

  const bulkApplyAction = () => {
    if (!selectedUsers.length) {
      throw new Error('Please select Users')
    }
    const callback = () => {
      dispatchModal({})
      setRowSelection({})
      updateInputState('tenantUserRoleExpiryDate', '')
      setSelectedAction('')
      setAddLabels([])
    }
    const actionMapper = {
      extend: {
        action: () =>
          extendMembers(
            {
              tenantAcr: acronym,
              daimlerIds: selectedUsers.map(({ daimlerId }) => daimlerId),
              ...extendFormData,
            },
            callback,
          ),
      },
      remove: {
        action: () =>
          batchRemoveMembers(
            {
              tenantAcr: acronym,
              daimlerIds: selectedUsers.map(({ daimlerId }) => daimlerId),
            },
            callback,
          ),
      },
      addLabel: {
        action: () => {
          bacthUpdateMemberLabels(
            {
              tenantAcr: acronym,
              daimlerIds: selectedUsers.map(({ daimlerId }) => daimlerId),
              labels: addLabels.map((value) => value.value),
            },
            callback,
          )
        },
      },
    }

    dispatchModal({
      variant: 'tenantMembersModal',
      action: selectedAction,
      data: {
        tenantUserRoleExpiryDate:
          selectedAction === 'extend'
            ? extendFormData.tenantUserRoleExpiryDate
            : undefined,
        users: selectedUsers,
        addedLabels: selectedAction === 'addLabel' ? addLabels : [],
      },
      onApprove: actionMapper[selectedAction]?.action,
      onCancel: () => dispatchModal({}),
    })
  }

  const onDeleteUser = (index) => {
    dispatchModal({
      variant: 'tenantMembersModal',
      action: 'remove',
      data: {
        users: [data[index]],
      },
      onApprove: () => {
        removeMember(
          {
            tenantAcr: acronym,
            daimlerId: data[index].daimlerId,
          },
          () => {
            setRowSelection({})
            dispatchModal({})
          },
        )
      },
      onCancel: () => dispatchModal({}),
    })
  }
  const setExpiringTenantMembers = async () => {
    try {
      const expiringMembers = await fetchExpiringTenantMembers(acronym)
      const newData = generateSortedAndFilteredTenantMembers(
        selectedRoles,
        selectedLabels,
        expiringMembers.map(
          ({ daimlerId }) => currentMembersObj.current?.[daimlerId],
        ),
      )
      const indices = {}
      expiringMembers.forEach((_, index) => (indices[index] = true))
      setRowSelection(indices)
      setData(newData)
    } catch (err) {
      setShowExpiring(false)
      notify({ message: err.message, mode: 'error' })
    }
  }

  const setNewData = () => {
    currentMembersObj.current = {}
    if (currentTenantMembers.length) {
      currentTenantMembers.forEach((member) => {
        currentMembersObj.current[member.daimlerId] = member
      })
    }

    if (showExpiring && currentTenantMembers.length) {
      setExpiringTenantMembers()
      setSelectedAction('extend')
    } else {
      setRowSelection({})
      setData(
        generateSortedAndFilteredTenantMembers(
          selectedRoles,
          selectedLabels,
          currentTenantMembers,
        ),
      )
    }
  }

  useEffect(() => {
    setNewData()
  }, [currentTenantMembers, selectedRoles, selectedLabels, showExpiring])

  const editTableRow = (index) => {
    if (editableRowIndex) {
      setNewData()
    }
    setEditableRowIndex(parseInt(index))
  }

  const columns = useMemo(
    () =>
      generateColumns({
        roles: rolesList,
        rolesObject,
        editableRowIndex,
        currentMembersObj,
        editTableRow,
        changeMemberRoles,
        onDeleteUser,
        revertStateChanges: setNewData,
      }),
    [rolesList],
  )

  const table = useReactTable({
    data,
    columns,
    autoResetPage: !skipPageReset,
    state: {
      rowSelection,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setSkipPageReset(true)
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          }),
        )
      },
    },
  })

  const deselectAllUsers = () => {
    setRowSelection({})

    if (searchParams.has('expiring')) {
      searchParams.delete('expiring')
      history.replace({ search: searchParams.toString() })
    }
  }

  return (
    <>
      <TableHeader
        rowSelection={rowSelection}
        data={data}
        selectedAction={selectedAction}
        extendFormData={extendFormData}
        globalFilter={globalFilter}
        tenantRoles={tenantRoles}
        selectedRoles={selectedRoles}
        selectedLabels={selectedLabels}
        addLabels={addLabels}
        table={table}
        showExpiring={showExpiring}
        currentTenantMembers={currentTenantMembers}
        actions={{
          deselectAllUsers,
          setSelectedAction,
          updateInputState,
          bulkApplyAction,
          setSelectedRoles,
          setSelectedLabels,
          setGlobalFilter,
          setShowExpiring,
          setAddLabels,
        }}
      />
      <TanstackTableWrapper table={table} />
    </>
  )
}

TenantMembers.propTypes = {
  currentTenantMembers: PropTypes.arrayOf(
    PropTypes.shape({ daimlerId: PropTypes.string }),
  ),
  tenantRoles: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, description: PropTypes.string }),
  ),
  dispatchModal: PropTypes.func,
  extendMembers: PropTypes.func,
  notify: PropTypes.func,
  removeMember: PropTypes.func,
  updateMemberRoles: PropTypes.func,
  batchRemoveMembers: PropTypes.func,
  bacthUpdateMemberLabels: PropTypes.func,
}

export const TenantMembersTable = connect(
  ({ tenants: { currentTenantMembers, tenantRoles }, sso }) => ({
    currentTenantMembers,
    tenantRoles,
    userData: sso,
  }),
  {
    dispatchModal: setModal,
    removeMember: removeTenantMember,
    batchRemoveMembers: batchRemoveTenantMembers,
    extendMembers: extendTenantMembers,
    notify: postNotification,
    updateMemberRoles: updateTenantMemberRoles,
    bacthUpdateMemberLabels: bacthUpdateTenantMemberLabels,
  },
)(TenantMembers)
