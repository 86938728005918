import React from 'react'
import moment from 'moment'
import { RolesSelector } from './components/RolesSelector'
import { LabelSelector } from './components/LabelSelector'
import { MemberUntilDateEditor } from './components/MemberUntilDateEditor'
import { IndeterminateCheckbox, Button } from '../../../../../common'

export const bulkActions = [
  {
    id: 'extend',
    name: 'Extend User(s)',
  },
  {
    id: 'remove',
    name: 'Remove User(s)',
  },
  {
    id: 'addLabel',
    name: 'Add Label(s)',
  },
]

export const trimString = (str, textSize) =>
  str.length > textSize ? str.substr(0, textSize) + '…' : str

export const checkIfrolesWereModified = (oldRoles, newRoles) => {
  if (oldRoles?.length !== newRoles?.length) {
    return true
  }
  if (newRoles) {
    for (const role of newRoles) {
      if (!oldRoles?.includes(role)) {
        return true
      }
    }
  }
  return false
}

export const valueRenderer = (selected, textSize = 20) => {
  if (!Array.isArray(selected)) {
    throw new Error('Array expected!')
  }
  const selectedValuesToText = selected.map(({ label }) => label).join(', ')

  return (
    <span title={selectedValuesToText}>
      {trimString(selectedValuesToText, textSize)}
    </span>
  )
}

export const generateActionButtonProps = (
  row,
  editableRowIndex,
  { editTableRow, revertStateChanges, changeMemberRoles, onDeleteUser },
) => {
  const { id, index } = row
  const rowId = parseInt(id)
  const isCurrentRow = editableRowIndex === rowId
  return {
    actions: {
      secondary: isCurrentRow
        ? () => {
            editTableRow(-1)
            revertStateChanges()
          }
        : () => editTableRow(rowId),
      primary: isCurrentRow
        ? () => changeMemberRoles(index)
        : () => onDeleteUser(rowId),
    },
    icons: {
      primary: isCurrentRow ? 'checkmark' : 'trash',
      secondary: isCurrentRow ? 'close' : 'pencil',
    },
  }
}

export const checkMemberExpired = (tenantMemberUntil) =>
  moment(tenantMemberUntil).isBefore(moment())

export const sortByRowsSelected = (members, daimlerIds) => {
  daimlerIds.sort((a, b) => a > b)
  members.sort((member1, member2) => {
    if (
      daimlerIds.includes(member1.daimlerId) &&
      !daimlerIds.includes(member2.daimlerId)
    ) {
      return -1
    } else if (
      !daimlerIds.includes(member1.daimlerId) &&
      daimlerIds.includes(member2.daimlerId)
    ) {
      return 1
    }
    return 0
  })
  return members
}

export const generateSortedAndFilteredTenantMembers = (
  selectedRoles,
  selectedLabels,
  members,
) => {
  let data = members
  if (selectedRoles.length) {
    const rolesArray = selectedRoles.map(({ value }) => value)
    data = data.filter((member) =>
      member.roles.some((role) => rolesArray.includes(role)),
    )
  }

  if (selectedLabels.length) {
    const labelsArray = selectedLabels.map(({ value }) => value)
    data = data.filter((member) =>
      member.labels.some((label) => labelsArray.includes(label)),
    )
  }

  const orderOfRoles = ['PO', 'PM', 'ISA', 'BUDGET_APPROVER']

  data.sort((member1, member2) => {
    for (const role of orderOfRoles) {
      if (member1.roles.includes(role) && !member2.roles.includes(role)) {
        return -1
      } else if (
        !member1.roles.includes(role) &&
        member2.roles.includes(role)
      ) {
        return 1
      }
    }
    return `${member1.firstName}${member1.lastName}${member1.daimlerId}` >
      `${member2.firstName}${member2.lastName}${member2.daimlerId}`
      ? 1
      : -1
  })

  return data
}

export const optionLabels = (members) => {
  const options = [...new Set(members.map((user) => user.labels).flat())]
  return options?.map((label) => ({ label, value: label }))
}

export const generateColumns = ({
  roles,
  rolesObject,
  currentMembersObj,
  editableRowIndex,
  editTableRow,
  revertStateChanges,
  changeMemberRoles,
  onDeleteUser,
}) => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ row }) => (
      <div>
        {row.original.firstName} {row.original.lastName}
      </div>
    ),
    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
  },
  {
    header: 'MBAG ID',
    accessorKey: 'daimlerId',
    cell: ({ row }) => <div>{row.original.daimlerId}</div>,
  },
  {
    header: 'E-Mail',
    accessorKey: 'email',
    cell: ({ row }) => <div>{row.original.email}</div>,
  },
  {
    header: 'Roles',
    accessorKey: 'roles',
    maxWidth: '200',
    cell: ({ getValue, row: { index, original }, table, column: { id } }) => {
      const rowOptions = getValue().map((role) => ({
        label: rolesObject[role],
        value: role,
      }))

      return (
        <RolesSelector
          rowOptions={rowOptions}
          roles={roles}
          editMode={editableRowIndex === index}
          onRolesInputChange={(memberRoles) => {
            table.options.meta?.updateData(
              index,
              id,
              memberRoles.map(({ value }) => value),
            )
          }}
          disabled={checkMemberExpired(original.tenantMemberUntil)}
        />
      )
    },
  },
  {
    header: 'Labels',
    accessorKey: 'labels',
    cell: ({ getValue, row: { index, original }, table, column: { id } }) => {
      const rowOptions = getValue()
        ? getValue().map((label) => ({
            label: label,
            value: label,
          }))
        : []

      return (
        <LabelSelector
          editMode={editableRowIndex === index}
          onLabelInputChange={(labels) => {
            table.options.meta?.updateData(
              index,
              id,
              labels.map(({ value }) => value),
            )
          }}
          value={rowOptions}
          options={optionLabels(table.options.data)}
          disabled={checkMemberExpired(original.tenantMemberUntil)}
        />
      )
    },
  },
  {
    header: 'Expiration Date',
    accessorKey: 'tenantMemberUntil',
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
      return (
        <MemberUntilDateEditor
          date={getValue()}
          expired={checkMemberExpired(getValue())}
          editMode={editableRowIndex === index}
          onDateChange={(val) => {
            table.options.meta?.updateData(index, id, val)
          }}
        />
      )
    },
  },
  {
    header: 'Actions',
    cell: ({ row }) => {
      const rowId = parseInt(row.id)
      const { actions, icons } = generateActionButtonProps(
        row,
        editableRowIndex,
        { editTableRow, revertStateChanges, changeMemberRoles, onDeleteUser },
      )
      const currentMember = currentMembersObj.current[row.original.daimlerId]
      const checkIfDateWasModified = (oldDate, newDate) => oldDate !== newDate

      const isDisabled = () =>
        editableRowIndex === rowId &&
        currentMember &&
        !checkIfrolesWereModified(currentMember.roles, row.original.roles) &&
        !checkIfDateWasModified(
          currentMember.tenantMemberUntil,
          row.original.tenantMemberUntil,
        ) &&
        !checkIfrolesWereModified(currentMember.labels, row.original.labels)

      return (
        <div className="tenant-members__action-buttons">
          <Button
            onClick={actions.secondary}
            withIcon={`bds/${icons.secondary}/16`}
            iconOnly
            size="small"
            variant="secondary"
          />
          <Button
            onClick={actions.primary}
            size="small"
            withIcon={`bds/${icons.primary}/16`}
            iconOnly
            disabled={
              isDisabled() ||
              !row.original.roles.length ||
              (editableRowIndex === rowId &&
                checkMemberExpired(row.original.tenantMemberUntil))
            }
            variant="primary"
          />
        </div>
      )
    },
  },
]
