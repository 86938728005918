import React from 'react'
import PropTypes from 'prop-types'
import { EventsSelctor } from '../../EventsSelctor'
import { TenantsSelector } from '../../TenantsSelector'
import { ProvidersSelector } from '../../ProvidersSelector'
import { ProtocolGenerator } from '../../ProtocolGenerator'
import { ProducersSelector } from '../../ProducersSelector'
import { WbCard } from '../../WbCard'
import { Button } from '../../../../../../common'
import {
  Modal,
  ModalFooter,
  ModalContent,
} from '../../../../../../common/ModalComponents'

export const WebHooksFormComponent = ({
  action,
  controls,
  loading,
  ProtocolSubFormControls,
  actions: { updateInputState, onChangeProducer, saveWebHook, onCancel },
}) => (
  <Modal mode="edit" className="">
    <ModalContent headline={`${action} webHook`}>
      <WbCard title="Protocol">
        <ProtocolGenerator
          value={controls.protocol.value}
          subFormState={ProtocolSubFormControls[controls.protocol.value]?.state}
          onChangeSubformInput={
            ProtocolSubFormControls[controls.protocol.value]?.setState
          }
          onChange={(val) => updateInputState('protocol', val)}
        />
      </WbCard>
      <WbCard title="Producer">
        <ProducersSelector
          value={controls.producer.value}
          valid={controls.producer.touched && controls.producer.valid}
          onChange={onChangeProducer}
          touched={controls.producer.touched}
        />
      </WbCard>
      <WbCard title="Providers">
        <ProvidersSelector
          control={controls.providers}
          producerSlug={controls.producer.value}
          onChange={(val) => updateInputState('providers', val)}
        />
      </WbCard>
      <WbCard title="Tenants">
        <TenantsSelector
          value={controls.tenants.value}
          valid={controls.tenants.touched && controls.tenants.valid}
          onChange={(val) => updateInputState('tenants', val)}
          touched={controls.tenants.touched}
        />
      </WbCard>
      <WbCard title="Events">
        <EventsSelctor
          control={controls.events}
          producerSlug={controls.producer.value}
          onChange={(val) => updateInputState('events', val)}
        />
      </WbCard>
    </ModalContent>
    <ModalFooter>
      <Button variant="secondary" size="large" onClick={onCancel}>
        Close
      </Button>
      <Button
        variant="primary"
        size="large"
        onClick={saveWebHook}
        disabled={loading}
      >
        {action} webHook {loading ? '...' : ''}
      </Button>
    </ModalFooter>
  </Modal>
)

const controlTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valid: PropTypes.bool,
  touched: PropTypes.bool,
}

WebHooksFormComponent.propTypes = {
  action: PropTypes.string,
  loading: PropTypes.bool,
  controls: PropTypes.shape({
    protocol: PropTypes.shape(controlTypes),
    producer: PropTypes.shape(controlTypes),
    providers: PropTypes.shape(controlTypes),
    tenants: PropTypes.shape(controlTypes),
    events: PropTypes.shape(controlTypes),
  }),
  ProtocolSubFormControls: PropTypes.object,
  actions: PropTypes.shape({
    updateInputState: PropTypes.func,
    onChangeProducer: PropTypes.func,
    saveWebHook: PropTypes.func,
    onCancel: PropTypes.func,
  }),
}
