import React from 'react'
import PropTypes from 'prop-types'
import { Button, DropdownMenu, ButtonGroup } from '../../../../common'

export const TenantActions = ({
  title,
  provider,
  position,
  isEditable,
  isEditing,
  isWhitelisted,
  isUserDeveloper,
  isTDMSAdmin,
  onEditClick,
  onMembersClick,
  onLogsClick,
  onBookingsClick,
  onDeleteClick,
  onAkamaiCacheInvalidation,
  onChangeProviderClick,
}) => {
  const dropDownMenu = [
    {
      label: 'Bookings',
      icon: 'edit',
      onClick: onBookingsClick,
      visible: !isEditing,
    },
    {
      label: 'Logs',
      icon: 'bds/statistics/24',
      onClick: onLogsClick,
      visible: !isEditing,
    },
    {
      label: 'Invalidate CDN Caches',
      icon: 'bds/reset/24',
      onClick: onAkamaiCacheInvalidation,
      visible: !isEditing,
    },
    {
      label: 'Change Provider',
      icon: 'bds/download-print/24',
      onClick: onChangeProviderClick,
      visible: isTDMSAdmin,
    },
    {
      label: 'Remove Tenant',
      icon: 'delete',
      onClick: onDeleteClick,
      visible: !isEditing && isWhitelisted && isTDMSAdmin,
    },
  ]

  const buttonGroup = [
    {
      label: 'Invalidate CDN Caches',
      icon: 'bds/reset/24',
      onClick: onAkamaiCacheInvalidation,
      visible: !(!isEditing && isWhitelisted) && isUserDeveloper,
    },
    {
      label: 'Edit',
      icon: 'edit',
      onClick: onEditClick,
      visible: !isEditing && isEditable,
    },
    {
      label: 'Members',
      icon: 'edit',
      onClick: onMembersClick,
      visible: !isEditing && isEditable,
    },
  ]

  return (
    <div className={`tenant-actions tenant-actions--${position}`}>
      {position === 'top' && (
        <h2 className="tenant-actions__title">
          {title ? (
            <>
              {title} <sup>[{provider}]</sup>
            </>
          ) : (
            'New Tenant'
          )}
        </h2>
      )}
      <div className="tenant-actions__buttons">
        <ButtonGroup>
          {buttonGroup.map(
            ({ visible, label, icon, onClick }) =>
              visible && (
                <Button
                  key={label}
                  variant="secondary"
                  size="small"
                  className="wb-button--theme-dark edit"
                  withIcon={icon}
                  onClick={onClick}
                >
                  {label}
                </Button>
              ),
          )}
          {!isEditing && isEditable && (
            <DropdownMenu
              items={dropDownMenu.filter(({ visible }) => visible)}
              buttonProps={{
                variant: 'secondary',
                size: 'small',
                className: 'wb-button--theme-dark',
                iconOnly: true,
                withIcon: 'bds/more/24',
              }}
            />
          )}
        </ButtonGroup>
      </div>
    </div>
  )
}

TenantActions.propTypes = {
  title: PropTypes.string,
  provider: PropTypes.string,
  position: PropTypes.string,
  isEditable: PropTypes.bool,
  isEditing: PropTypes.bool,
  isWhitelisted: PropTypes.bool,
  isUserDeveloper: PropTypes.bool,
  isTDMSAdmin: PropTypes.bool,
  onEditClick: PropTypes.func,
  onMembersClick: PropTypes.func,
  onLogsClick: PropTypes.func,
  onBookingsClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAkamaiCacheInvalidation: PropTypes.func,
  onChangeProviderClick: PropTypes.func,
}
