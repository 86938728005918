import { tdmsClient } from '../apiClient'
import { _fetch } from '../customFetch'
import { BASE_URL } from '../constants'

export const getTdmsUsers = async () =>
  tdmsClient.client.userMethods.listAssignedUsers()

export const fetchUserRoles = async (userData) =>
  tdmsClient.client.userMethods.listUserRoles(userData?.id)

export const fetchAllRoles = async () =>
  tdmsClient.client.userMethods.listWithRoles()

export const saveUserRole = async ({ daimlerId, roles }) =>
  tdmsClient.client.userMethods.setRoles(daimlerId, roles)

export const fetchAllUsers = async () => {
  const response = await tdmsClient.client.userMethods.list()
  response.sort((user1, user2) =>
    user1.daimlerId.localeCompare(user2.daimlerId),
  )
  return response
}

export const getUser = async (daimlerId) =>
  tdmsClient.client.userMethods.getByDaimlerId(daimlerId)

export const findUsers = async (
  query = '',
  page = 0,
  size = 20,
  local = false,
) =>
  tdmsClient.client.userMethods.query({
    query,
    page,
    size,
    local,
  })

export const submitCurrentUser = async (daimlerId, user) =>
  tdmsClient.client.userMethods.saveUser(daimlerId, user)

export const listAllApiKeys = async () => {
  const response = await _fetch(`${BASE_URL}/api-keys`, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.json()
}

export const createApiKey = async (apiKeyData) => {
  const response = await _fetch(`${BASE_URL}/api-keys`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(apiKeyData),
  })
  return response.text()
}

export const deleteApiKey = async (apiKeyId) => {
  const response = await _fetch(`${BASE_URL}/api-keys/${apiKeyId}`, {
    method: 'DELETE',
  })
  return response.text()
}
