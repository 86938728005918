import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox, ErrorControl } from '../../../../../../common'

export const EventsSelctorComponent = ({
  eventTypes,
  value,
  dense,
  disabled,
  invalid,
  onCheckBoxClick,
}) => (
  <>
    <wb-grid-row
      className="wh-events-selection"
      style={{ height: dense ? '50px' : '' }}
    >
      {Object.keys(eventTypes).map((groupKey) => {
        const itemsList = eventTypes[groupKey].map((event) => (
          <CheckBox
            disabled={disabled}
            key={event.slug}
            className="wb-margin-bottom-xs"
            checked={value.includes(event.slug)}
            onChange={() => onCheckBoxClick(event.slug)}
            label={event.name}
          />
        ))
        return (
          <div key={groupKey} className="wh-events-selection__item">
            <h4 className="wb-margin-bottom-xs">{groupKey}</h4>
            {itemsList}
          </div>
        )
      })}
    </wb-grid-row>
    <ErrorControl error={invalid && 'Please select at least one item'} />
  </>
)

EventsSelctorComponent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  dense: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  eventTypes: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        producerId: PropTypes.number,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        group: PropTypes.number,
      }),
    ),
  ),
  onCheckBoxClick: PropTypes.func,
}
