import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TenantActions } from '../tenantActions'
import { TenantBasicInfo } from '../tenantBasicInfo'
import { TenantMembers } from '../tenantMembers'
import { TenantLinks } from '../tenantLinks'
import { TenantDocLink } from '../tenantDocLink'
import { TenantCostOverview } from '../tenantCostOverview'
import { fetchBookings, fetchBookingServiceProperty } from '../../../../../http'
import { TenantControlling } from '../tenantControlling'
import { TenantComplianceInfo } from '../tenantComplianceInfo/TenantComplianceInfo'
import { isUserDeveloper } from '../../../../../util'

const currDate = new Date()

export const checkIfBookingIsCurrent = (booking) =>
  !(
    new Date(booking.startDate) > currDate ||
    (booking.endDate && new Date(booking.endDate) < currDate)
  )

export const getCurrentGhoBookings = (tenantBookings) =>
  tenantBookings.filter(
    (booking) =>
      booking.packageSlug === 'github-organization' &&
      checkIfBookingIsCurrent(booking),
  )

export const getGhoServiceProperties = async (ghoBookings = []) => {
  const ghoPromises = ghoBookings.map(({ id }) =>
    Promise.resolve(
      fetchBookingServiceProperty(
        id,
        'github-organization',
        'organization-login',
      ),
    ),
  )
  return Promise.all(ghoPromises)
}

export const TenantInformation = ({
  arts,
  selectedTenant,
  tenantInfo,
  isEditable,
  userRoles,
  isWhitelisted,
  username,
  onComplianceInfoClick,
  onAkamaiCacheInvalidation,
  onChangeProviderClick,
  onEditClick,
  onDeleteClick,
  onMembersClick,
  onLogsClick,
  onBookingsClick,
}) => {
  const [state, setState] = useState({
    bookings: [],
    githubOrganizations: [],
  })

  useEffect(() => {
    const loadBookings = async () => {
      const bookingDetails = await fetchBookings({
        acronym: selectedTenant,
        details: true,
      })
      const githubOrganizationServiceProperties = await getGhoServiceProperties(
        getCurrentGhoBookings(bookingDetails),
      )
      setState({
        bookings,
        githubOrganizations: githubOrganizationServiceProperties.filter(
          (org) => !!org.value,
        ),
      })
    }
    selectedTenant && loadBookings()
  }, [selectedTenant])

  const actionProps = {
    isEditable,
    isWhitelisted,
    editButtonLabel: 'Edit',
    onEditClick,
    onDeleteClick,
    onMembersClick,
    onLogsClick,
    onBookingsClick,
    onComplianceInfoClick,
    onAkamaiCacheInvalidation,
    onChangeProviderClick,
    isEditing: false,
    isUserDeveloper: isUserDeveloper(tenantInfo, username),
    isTDMSAdmin: userRoles?.includes('admin'),
  }

  const tenantArt = tenantInfo.agileReleaseTrain
    ? arts.find((art) => art.key === tenantInfo.agileReleaseTrain)
    : null
  const tenantData = tenantArt
    ? { ...tenantInfo, agileReleaseTrain: tenantArt.value }
    : tenantInfo

  const { bookings, githubOrganizations } = state
  return (
    <div className="tenant-content">
      <TenantActions
        position="top"
        provider={tenantInfo.providerSlug}
        title={selectedTenant}
        {...actionProps}
      />
      <div className="tenants-info tenant-body">
        <TenantBasicInfo info={tenantData} />
        {!!tenantData.docLink && <TenantDocLink docLink={tenantData.docLink} />}
        {!!githubOrganizations.length && (
          <TenantLinks githubOrganizations={githubOrganizations} />
        )}
        <TenantMembers tenantInfo={{ ...tenantData }} isEditable={isEditable} />
        <TenantCostOverview bookings={bookings} />
        <TenantControlling info={tenantData} />
        <TenantComplianceInfo />
      </div>
      <TenantActions position="bottom" {...actionProps} />
    </div>
  )
}

TenantInformation.propTypes = {
  arts: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
  ),
  userRoles: PropTypes.arrayOf(PropTypes.string),
  username: PropTypes.string,
  selectedTenant: PropTypes.string,
  tenantInfo: PropTypes.object,
  isEditable: PropTypes.bool.isRequired,
  isWhitelisted: PropTypes.bool.isRequired,
  onComplianceInfoClick: PropTypes.func.isRequired,
  onAkamaiCacheInvalidation: PropTypes.func.isRequired,
  onChangeProviderClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onMembersClick: PropTypes.func.isRequired,
  onLogsClick: PropTypes.func.isRequired,
  onBookingsClick: PropTypes.func.isRequired,
}
