import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getWebHookEvents } from '../../../../../../../data/actions'
import { CheckBox } from '../../../../../../common'
import { EventsSelctorComponent } from '../component'

const groupEvents = (events) => {
  const eventTypes = {}
  events.forEach((event) => {
    eventTypes[event.category] = eventTypes[event.category] || []
    eventTypes[event.category].push(event)
  })
  return eventTypes
}

const EventsSelctorContainer = ({
  control,
  events,
  producerSlug,
  getProducerEvents,
  onChange,
}) => {
  const { valid, value, touched } = control
  const [chooseForAll, setChooseForAll] = useState(false)

  useEffect(() => {
    getProducerEvents(producerSlug)
  }, [producerSlug])

  useEffect(() => {
    const chooseAll = value === '*'
    if (chooseForAll !== chooseAll) {
      setChooseForAll(chooseAll)
    }
  }, [value])

  const onCheckBoxClick = (eventId) => {
    const newEventTypes = [...value]
    const eventIndex = newEventTypes.findIndex(
      (eventType) => eventType === eventId,
    )
    if (eventIndex > -1) {
      newEventTypes.splice(eventIndex, 1)
    } else {
      newEventTypes.push(eventId)
    }
    onChange(newEventTypes)
  }

  const onClickChooseForAll = () => {
    chooseForAll ? onChange([]) : onChange('*')
    setChooseForAll(!chooseForAll)
  }

  return (
    <>
      <CheckBox
        checked={chooseForAll}
        onChange={onClickChooseForAll}
        label={`Register for all Events`}
      />
      <EventsSelctorComponent
        invalid={touched && !valid}
        dense={!producerSlug}
        disabled={chooseForAll}
        eventTypes={groupEvents(events)}
        value={value}
        onCheckBoxClick={onCheckBoxClick}
      />
    </>
  )
}

export const EventsSelctor = connect(({ hermes: { events } }) => ({ events }), {
  getProducerEvents: getWebHookEvents,
})(EventsSelctorContainer)

EventsSelctorContainer.propTypes = {
  control: PropTypes.shape({
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
    }),
  ),
  producerSlug: PropTypes.string,
  getProducerEvents: PropTypes.func,
  onChange: PropTypes.func,
}
