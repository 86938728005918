import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { MenuItem } from './MenuItem'
import { Button } from '../../common'

const logoImage = 'assets/nebula_logo_2021.png'

export const HeaderComponent = ({
  menuItems,
  isEditing,
  navMenuSelected,
  actions: { goToHelp, onClickLogOut, goToHome },
}) => (
  <div className="header">
    <wb-grid>
      <wb-grid-row>
        <wb-grid-col mq1="12">
          <div className="header__title-container">
            <h1 className="header__title">Nebula Applications</h1>
            <Button
              variant="tertiary"
              size="small"
              onClick={goToHelp}
              withIcon="service-local"
              className="wb-margin-start-xxs"
            >
              Need help?
            </Button>
            <Button
              variant="primary"
              size="small"
              className="wb-margin-start-xs"
              onClick={onClickLogOut}
            >
              Logout
            </Button>
          </div>
        </wb-grid-col>
        <wb-grid-col mq1="12">
          <nav
            className={classnames('main-menu', {
              'main-menu--disabled': isEditing,
            })}
          >
            <ul className="main-menu__container">
              {menuItems.top
                .filter((item) => item.showItem)
                .map(({ path, title, onClick, props }) => (
                  <MenuItem
                    key={title}
                    title={title}
                    isActive={navMenuSelected === path}
                    onClick={onClick}
                    {...props}
                  />
                ))}
            </ul>
            <ul className="main-menu__container">
              {menuItems.bottom
                .filter((item) => item.showItem)
                .map(({ path, title, onClick, props }) => (
                  <MenuItem
                    key={title}
                    title={title}
                    isActive={navMenuSelected === path}
                    onClick={onClick}
                    {...props}
                  />
                ))}
            </ul>
          </nav>
        </wb-grid-col>
      </wb-grid-row>
      <div className="header__logo">
        <Button onClick={goToHome} variant="tertiary">
          <img
            id="header__image"
            className={`header__image header__image--bigger`}
            src={logoImage}
            alt="Mercedes-Benz"
          />
        </Button>
      </div>
    </wb-grid>
  </div>
)

const menuItemPropTypes = PropTypes.shape({
  path: PropTypes.string,
  title: PropTypes.string,
  showItem: PropTypes.bool,
  onClick: PropTypes.func,
  props: PropTypes.shape({
    target: PropTypes.string,
    rel: PropTypes.string,
    href: PropTypes.string,
  }),
})
HeaderComponent.propTypes = {
  menuItems: PropTypes.shape({
    top: PropTypes.arrayOf(menuItemPropTypes),
    bottom: PropTypes.arrayOf(menuItemPropTypes),
  }),
  isEditing: PropTypes.bool,
  navMenuSelected: PropTypes.string,
  actions: PropTypes.shape({
    goToHelp: PropTypes.func,
    onClickLogOut: PropTypes.func,
    goToHome: PropTypes.func,
  }),
}
