import React from 'react'
import PropTypes from 'prop-types'
import { ErrorControl } from '../ErrorControl'
import { Hint } from '../Hint'

export const Input = ({
  type,
  value,
  label,
  disabled,
  style,
  error,
  hint,
  onChange,
  ...rest
}) => (
  <wb-input-control>
    <span>
      <wb-input>
        <label>{label}</label>
        <input
          type={type}
          value={value}
          onChange={onChange}
          style={style}
          disabled={disabled}
          {...rest}
        />
      </wb-input>
      <ErrorControl error={error} />
      <Hint text={hint} />
    </span>
  </wb-input-control>
)
Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  onChange: PropTypes.func,
}
